/*
 * This file is part of Part-DB (https://github.com/Part-DB/Part-DB-symfony).
 *
 *  Copyright (C) 2019 - 2022 Jan Böhmer (https://github.com/jbtronics)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU Affero General Public License as published
 *  by the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU Affero General Public License for more details.
 *
 *  You should have received a copy of the GNU Affero General Public License
 *  along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

/******************************
* Bootstrap treeview overrides
*******************************/
/* Set display style to flexbox, so wraped text is aligned to */

.list-group-item {
    padding: 5px 10px;
    display: flex;
}

.treeview {
    font-size: 12px;
}

@media (max-width: 768px) {
    .treeview span.icon {
        width: 20px !important;
        height: 20px !important;
    }
}

.fa-treeview {
    line-height: 1.428;
}

.treeview-sm .list-group-item {
    padding-top: 3px;
    padding-bottom: 3px;
}

.treeview .badge {
    margin-left: auto;
    line-height:  1.42857143;
}