/*
 * This file is part of Part-DB (https://github.com/Part-DB/Part-DB-symfony).
 *
 *  Copyright (C) 2019 - 2023 Jan Böhmer (https://github.com/jbtronics)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU Affero General Public License as published
 *  by the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU Affero General Public License for more details.
 *
 *  You should have received a copy of the GNU Affero General Public License
 *  along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

.hoverpic {
    min-width: 10px;
    max-width: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.thumbnail-sm {
    max-height: 100px;
}

.avatar-xs {
    height: 1.2em;
    width: 1.2em;
    object-fit: cover;
    border-radius: 5px;
}

.entity-image-xs {
    height: 1.2em;
    width: 2.0em;
    object-fit: contain;
}

.structural-entity-select-image {
    height: 1.5em;
    max-width: 2.0em;
    object-fit: contain;
}

.part-table-image {
    max-height: 40px;
    object-fit: contain;
}

.part-info-image {
    max-height: 350px;
    object-fit: contain;
}

.object-fit-cover {
    object-fit: cover;
}
